

#profile-picture {
  display: flex;
  justify-content: center;
}

#profile-picture img.avatar {
  object-fit: cover;
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

#profile-upload-holder {
  padding-top: 0.5em;
}

#profile-upload-holder input[type="file"] {
  display: none;
}
